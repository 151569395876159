import React from 'react'

import { graphql, Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const query = graphql`
    query($slug: String) {
        contentfulBlogPost (slug: {
            eq: $slug
        }) {
            title
            author
            date(formatString: "DD MMMM, YYYY")
            post {
                json
            }
        }
    }
    `

const PortalTemplate = (props) => {

    const { 
        title,
        author,
        date,
        post
     } = props.data.contentfulBlogPost

     const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) =>  
                <img src={`${node.data.target.fields.file["en-US"].url}`} 
                    alt={`${node.data.target.fields.description["en-US"]}`}/>
        }
      }

      let postBody = documentToReactComponents(post.json, options)
    
     return (
         <Layout>
             <SEO title={title} />


            <Link to="/portal" className="blogpost-back-btn is-size-4">
                Back
            </Link>

            <article className="section gradient portal-gradient-bg">
                <div className="container content">
                    <h1 className="is-size-3">{title}</h1>

                    <h3 className="is-size-4">by {author}</h3>

                    <h5 className="is-size-5">{date}</h5>

                    <span className="blogpost-body">{postBody}</span>

                </div>
            </article>
         </Layout>
     )

}

export default PortalTemplate